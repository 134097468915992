@import "responsive";

@include respond-to(tv) {
	.s-home::after {
		width: 970px;
		background-size: cover; }
	.s-home::before {
		right: 932px; }
	.s-services-cont::after {
		max-width: 1500px; }
	.s-services-cont::before {
		left: 1463px; } }

@include respond-to(desktop-wide) {
	.s-header {
		padding: 24px 0; }
	.s-header-menu li {
		margin-right: 30px; }
	.s-header-menu {
		margin-right: 20px; }
	.s-home::after {
		display: none; }
	.s-home::before {
		display: none; }
	.s-home-line {
		max-width: 1060px; }
	.s-home-contacts {
		padding-top: 48px;
		margin-left: 0; }
	.s-home-block {
		width: 100%; }
	.s-home-bottom {
		background-image: url("../img/home-bg.jpg");
		background-size: 100% 675px;
		background-repeat: no-repeat; }
	.s-home-info {
		padding: 0 2px;
		padding-top: 26px;
		padding-bottom: 33px; }
	.s-home-logo-wrap {
		margin-right: 0; }
	.s-home-logo {
		margin-top: 1px;
		margin-left: -9px; }
	.s-home-buttons {
		padding-left: 0;
		justify-content: flex-start;
		padding-bottom: 40px;
		padding-top: 50px; }
	.s-home {
		height: 789px; }
	.s-services-cont::before {
		left: auto;
		right: 0; }
	.s-services-cont::after {
		left: 0;
		top: 0;
		background-size: cover; }
	.s-reasons-item {
		margin-right: 50px; }
	.s-form::after {
		right: auto;
		left: 19px;
		background-size: cover;
		width: calc(100% - 19px); }
	.s-form::before {
		left: 19px;
		right: auto; }
	.s-form-cont {
		padding-left: 43px; }
	.s-form-form {
		padding-left: 50px; }
	.s-examples .owl-nav button.owl-prev {
		left: 0; }
	.s-examples .owl-nav button.owl-next {
		right: 0; }
	.s-footer-logo {
		margin-left: -9px; }
	.s-header1-logo {
		margin-right: 20px; } }


@include respond-to(desktop) {
	.s-header-addr {
		display: none; }
	.s-home-wrap {
		background: none; }
	.s-home-logo-wrap {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-content: stretch;
		flex-wrap: wrap; }
	.s-home-cats {
		max-width: 470px; }
	.s-services {
		background: none; }
	.s-services-top {
		background: #fff; }
	.s-services-top-line {
		padding-top: 41px; }
	.s-services-count {
		font-size: 100px;
		line-height: 108px;
		letter-spacing: -0.1em; }
	.s-services-numbers-item {
		width: 100%;
		padding-left: 6px;
		max-width: 340px; }
	.s-services-numbers-descr {
		line-height: 1;
		margin-left: -2px; }
	.s-services-numbers-item.item2 {
		padding-left: 12px;
		padding-top: 33px; }
	.s-services-numbers-item.item2 .s-services-count {
		font-size: 70px;
		line-height: 85px;
		letter-spacing: -0.08em;
		width: 129px; }
	.s-services-numbers-item.item2 .s-services-numbers-descr {
		width: calc(100% - 129px);
		font-size: 12px;
		line-height: 150%;
		letter-spacing: 0.3em; }
	.s-services-numbers-item.item2 {
		align-items: center; }
	.s-services-info {
		padding-left: 0;
		padding-top: 40px; }
	.s-services-top-block {
	 	+ .s-services-top-block {
	 		padding-top: 27px; } }
	.s-services-top {
		padding-bottom: 40px; }
	.s-services-info {
		padding-left: 0px; }
	.s-services-top-line {
		padding-top: 40px; }
	.s-services-top {
		padding-bottom: 70px; }
	.s-services-cont {
		padding: 70px 0; }
	.s-reasons-items {
		margin-top: 70px; }
	.s-reasons-items-line {
		padding-left: 0; }
	.s-reasons-item {
		width: 340px;
		margin-right: 40px; }
	.s-reasons-item.item2 {
		width: 33.3%; }
	.s-reasons-item {
		width: 33.3%;
		padding: 0 15px;
		margin-right: 0; }
	.s-reasons-item.item6 {
		width: 33.3%; }
	.s-reasons-item-text {
		padding-left: 15px; }
	.s-reasons {
		padding: 70px 0;
		background: none; }
	.s-form {
		padding-top: 49px;
		min-height: 1px;
		padding-bottom: 53px; }
	.s-form::after {
		right: auto;
		left: 19px;
		background-size: cover;
		width: calc(100% - 19px); }
	.s-form::before {
		left: 19px;
		right: auto; }
	.s-form-form {
		padding-left: 0; }
	.s-form-title {
		font-size: 45px;
		line-height: 1.2; }
	.s-sert {
		padding-top: 62px;
		background-image: none;
		padding-bottom: 70px; }
	.s-examples {
		background: none;
		&-inner {
			padding: 70px 0 60px; } }
	.s-clients {
		background: none;
		&-inner {
			padding: 70px 0; } }
	.s-footer-inner {
		padding: 24px 0; }
	.s-footer .s-header-menu li:hover {
		.s-header-menu-block {
			bottom: 80px; } }
	.s-footer .s-header-menu-block {
		bottom: 70px; }
	.s-footer .s-header-menu-block::before {
		left: 75px;
		right: auto; }
	.s-header1 .dropdown-wrapper {
		display: none; }
	.s-header1-toggle {
		display: block;
		cursor: pointer; }
	.s-menu {
		display: block; }
	.s-page {
		left: 0;
		&.active {
			left: -270px; } } }







@include respond-to(tablet-large) {
	.s-home-toggle {
		display: block;
		cursor: pointer;
		margin-top: 12px;
		margin-right: 14px; }
	.s-header-menu {
		display: none; }
	.s-header-inner {
		justify-content: center;
		width: 100%; }
	.s-header-soc {
		justify-content: space-between;
		width: 100%; }
	.s-home-bottom {
		background-image: url("../img/home-bg.jpg");
		background-size: 100% 513px;
		height: auto;
		background-repeat: no-repeat;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 513px;
			display: block;
			background-color: rgba(0,0,0,0.4);
			top: 0; } }
	.s-home {
		height: auto; }
	.s-home-logo-descr {
		padding-top: 3px; }
	.s-home-block {
		width: 100%; }
	.s-home-bottom {
		padding-top: 44px; }
	.s-home-title {
		font-size: 32px;
		line-height: 100%;
		color: #FFFFFF;
		padding-top: 0;
		margin-left: 0;
		max-width: 260px; }
	.s-home-descr {
		font-size: 22px;
		line-height: 26px;
		letter-spacing: -0.02em;
		color: #93DD45;
		margin-left: 3px;
		max-width: 222px; }
	.s-home-phone a {
		font-size: 26px;
		line-height: 30px;
		letter-spacing: -0.05em; }
	.s-home-contacts {
		margin-left: 0;
		padding-top: 42px; }
	.s-home-phone::after {
		background-image: url("../img/phone-after-mob.svg");
		width: 242px;
		height: 8px; }
	.s-home-phone {
		padding-bottom: 26px; }
	.s-home-callback-link {
		font-size: 14px;
		line-height: 18px;
		color: #93DD45;
		&:hover {
			color: #fff;
			svg {
				path {
					fill: #fff; } } }
		svg {
			width: 18px;
			height: 7px;
			path {
				fill: #93DD45; } } }
	.s-home-callback {
		padding-left: 0;
		padding-top: 13px; }
	.s-home-callback-link .text {
		padding-left: 3px; }
	.s-home-buttons {
		padding-top: 110px;
		padding-left: 0; }
	.s-home-button {
		margin-right: 0;
		width: 100%; }
	.s-home-btn1 {
		width: 100%; }
	.s-home-btn {
		width: 100%; }
	.s-home-btn1 .text-mob {
		display: block;
		span {
			padding: 0 6px; } }
	.s-home-btn1 .text-desc {
		display: none; }

	.s-home-btn1 {
		background-color: rgba(234,234,234,0.4);
		color: #8A8A8A;
		height: 70px;
		line-height: 77px;
		font-size: 14px;
		padding-left: 0;
		&:hover {
			background-color: rgba(234,234,234,1); } }
	.s-home-button + .s-home-button {
		margin-top: -5px; }
	.s-home-cats {
		padding-top: 31px; }
	.s-home-mail {
		margin-top: 0; }
	.s-home-buttons {
		padding-bottom: 0; }
	.s-services-top-block {
		width: 100%; }
	.s-services-cont::after {
		background: #F4F4F4; }
	.s-services-top {
		padding-bottom: 40px; }
	.s-reasons {
		padding-top: 42px;
		background: none;
		padding-bottom: 54px; }
	.s-reasons-descr {
		font-size: 18px;
		line-height: 160%;
		letter-spacing: -0.05em; }
	.s-reasons-title {
		line-height: 120%; }
	.s-reasons-title::after {
		width: 341px;
		left: -11px;
		bottom: 10px; }
	.s-reasons-items {
		margin-top: 0;
		padding-top: 33px; }
	.s-reasons-items-line {
		justify-content: flex-start;
		padding-left: 0; }
	.s-reasons-item {
		width: 100%;
		margin-right: 0;
		align-items: center;
		padding-bottom: 27px;
		&.hide-desc {
			display: flex; }
		&.hide-mob {
			display: none; }
		&:last-child {
			padding-bottom: 0; } }
	.s-reasons-item-text {
		padding-left: 30px;
		br {
			display: none; } }
	.s-reasons-item.item6 {
		width: 100%; }
	.s-reasons-items-line + .s-reasons-items-line {
		padding-top: 24px; }
	.s-reasons-item.item2 {
		width: 100%; }
	.s-reasons-item {
		padding-left: 0;
		padding-right: 0;
		width: 100%; }
	.s-form-subtitle {
		background-image: url("../img/form-subtitle-mob.svg");
		width: 221px;
		height: 42px;
		font-size: 14px;
		margin-left: -40px;
		padding-left: 42px;
		line-height: 42px; }
	.s-form-cont {
		padding-left: 38px; }
	.s-form-block {
		width: 100%; }
	.s-form-form {
		padding-top: 16px;
		padding-left: 38px; }
	.s-form-info {
		font-size: 13px;
		line-height: 160%; }
	.s-form-item {
		width: 100%;
		padding-right: 35px;
		+ .s-form-item {
			padding-top: 8px; } }
	.s-form-input {
		max-width: 100%;
		input {
			font-family: IBM Plex Sans; } }
	.s-form-fields {
		padding-top: 25px; }
	.b-checkbox input + label {
		font-size: 14px;
		line-height: 150%; }
	.s-form-agree {
		padding-top: 35px; }
	.s-form-button {
		padding-top: 30px;
		.s-form-btn {
			max-width: 269px;
			margin-left: 0; } }
	.s-form-cont {
		max-width: 455px; }
	.s-form-form {
		max-width: 455px; }
	.s-sert-title {
		font-size: 32px;
		line-height: 120%;
		letter-spacing: -0.05em;
		padding-bottom: 6px; }
	.s-sert-img img {
		height: 228px; }
	.s-sert-img {
		max-width: 162px;
		display: block; }
	.s-sert-link a {
		font-size: 12px;
		line-height: 130%;
		.br {
			display: block; } }
	.s-sert-link {
		padding-top: 14px; }
	.s-footer-logo-wrap {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-content: stretch;
		flex-wrap: wrap;
		width: 100%; }
	.s-footer-logo {
		width: 270px; }
	.s-footer-toggle {
		display: block;
		margin-top: 29px;
		cursor: pointer;
		margin-right: 15px; }
	.s-footer-scroll a {
		width: 54px;
		height: 54px;
		line-height: 54px; }
	.s-footer-scroll {
		right: 3px;
		top: -28px; }
	.s-footer-inner {
		padding: 37px 0; }
	.s-footer-logo {
		margin-left: -5px;
		align-items: flex-start;
		img {
			padding-top: 8px; } }
	.s-footer-logo a {
		width: 60px; }
	.s-footer-logo .text {
		padding-left: 11px;
		font-family: IBM Plex Sans;
		font-style: normal;
		font-weight: 300;
		font-size: 12px;
		line-height: 160%;
		color: #000000;
		span {
			font-family: IBM Plex Sans;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 160%;
			color: #000000;
			display: block; } }
	.s-contacts-block {
		position: relative;
		padding-top: 0;
		.wrap {
			padding: 0; } }
	.s-contacts-info {
		padding: 57px 19px;
		max-width: 100%;
		width: 100%; }
	.s-contacts-descr {
		padding-top: 31px;
		padding-left: 0;
		margin-right: -10px; }
	.s-contacts-phones {
		padding-left: 0;
		padding-top: 22px; }
	.s-contacts-descr.descr1 {
		padding-top: 25px; }
	.s-contacts-button {
		padding-left: 0;
		padding-top: 46px;
		.s-home-btn {
			max-width: 100%; } }
	.s-contacts-map {
		height: 508px; }
	.s-header1 .s-header-soc {
		display: none; } }



@include respond-to(tablet) {

	.s-home-cats {
		max-width: 100%;
		padding-top: 31px; }
	.s-home-cat {
		width: 100%;
		+ .s-home-cat {
			padding-top: 15px; } }
	.s-home-cat-item.item2 {
		margin-top: 0;
		padding: 42px 35px; }
	.s-home-cat-item {
		max-width: 100%;
		margin: 0 auto;
		padding: 40px 34px;
		height: 242px; }
	.s-home-cat-text {
		max-width: 200px;
		display: block; }
	.s-home-bottom {
		background-image: url("../img/home-bg-mob.jpg");
		background-size: 100% 513px;
		height: auto;
		background-repeat: no-repeat; }
	.s-services-info {
		padding-top: 0; }
	.s-services-title {
		font-size: 32px;
		line-height: 180%;
		letter-spacing: -0.05em; }
	.s-services-text {
		font-size: 14px;
		line-height: 180%;
		padding-top: 3px; }
	.s-services-cont-block {
		width: 100%;
		+ .s-services-cont-block {
			padding-top: 24px; } }
	.s-services-cont-list {
		font-size: 14px;
		line-height: 160%; }
	.s-services-cont {
		padding-top: 45px;
		padding-bottom: 51px; }
	.s-services-cont-list li {
		padding-left: 30px; }
	.s-services-cont-list li + li {
		margin-top: 21px; }
	.s-services-cont-list.list2 {
		margin-left: 0; }
	.s-services-cont-list li::before {
		left: 3px;
		top: 8px; }
	.s-reasons-item-text {
		padding-left: 30px;
		font-size: 14px;
		line-height: 160%; }


	.s-form {
		padding-top: 49px;
		min-height: 1px;
		padding-bottom: 53px; }
	.s-form::after {
		right: auto;
		left: 19px;
		background-image: url("../img/form-bg-mob.jpg");
		background-size: cover;
		width: calc(100% - 19px); }
	.s-form::before {
		left: 19px;
		right: auto; }
	.s-form-title {
		font-size: 28px;
		line-height: 36px;
		letter-spacing: -0.05em;
		padding-top: 21px;
		margin-right: 0;
		max-width: 265px; }
	.s-form-text {
		font-size: 18px;
		line-height: 23px;
		padding-top: 10px;
		max-width: 175px; }
	.s-sert-title::after {
		bottom: 0;
		left: 16px; }
	.s-sert-title {
		padding-bottom: 43px; }
	.s-sert-list {
		padding-top: 43px;
		margin: 0;
		margin-right: -200px;
		width: calc(100% + 200px); }
	.s-sert-list li {
		width: 198px;
		padding: 0; }
	.s-sert {
		padding-bottom: 61px; }


	.s-examples {
		background: none;
		.s-sert-title {
			padding-bottom: 45px; } }
	.s-examples-inner {
		background: none;
		padding-top: 0; }
	.s-examples-slider {
		margin-top: 53px;
		margin-right: -200px;
		width: calc(100% + 200px); }
	.s-examples-item {
		padding: 0 4px;
		width: 240px; }
	.s-examples-item-img {
		max-width: 178px; }
	.s-examples-item-img img {
		height: 141px; }
	.s-examples-item-title {
		padding-top: 27px; }
	.s-examples-item-descr {
		padding-top: 11px; }
	.s-examples-inner {
		padding-bottom: 15px; }
	.s-clients {
		background: none;
		.s-sert-title {
			padding-bottom: 14px; }
		.s-sert-title::after {
			display: none; } }
	.s-clients-inner {
		padding-top: 49px; }
	.s-clients-list .line {
		display: none; }
	.s-clients-list {
		margin: 0 -15px;
		width: calc(100% + 30px);
		padding-bottom: 47px; }
	.s-clients-list li {
		width: 165px; }
	.s-clients-item {
		height: 120px; }
	.s-clients-inner {
		padding-bottom: 55px; }
	.s-header1 {
		padding: 20px 0; }
	.s-popup {
		padding: 66px 30px 66px 70px; }
	.s-popup.thanks {
		padding: 66px 30px 66px 70px; } }














@include respond-to(mobile) {
	.s-header-soc li a {
		font-size: 12px;
		line-height: 16px; }
	.s-home-logo-descr {
		font-size: 10px;
		line-height: 12px;
		letter-spacing: -0.02em; }
	.s-home-mail a {
		font-size: 11px;
		line-height: 14px; }
	.s-home-info {
		justify-content: space-between; }
	.s-home-logo-descr {
		margin-right: 0;
		padding-top: 0; }
	.s-popup {
		padding: 30px 15px; }
	.s-popup.thanks {
		padding: 30px 15px; } }
@include respond-to(mobile1) {
	.s-home-cat-item {
		max-width: 324px;
		margin: 0 auto; }
	.s-home-btn1 {
		width: 100%;
		max-width: 324px;
		margin: 0 auto; }
	.s-home-btn {
		width: 100%;
		max-width: 324px;
		margin: 0 auto; }
	.s-services-cont-list {
		max-width: 326px; }
	.s-popup .mfp-close {
		top: 15px;
		right: 15px; } }

@include respond-to(mobile2) {
	.s-footer-logo {
		width: 254px; }
	.s-home-logo {
		max-width: 233px; }
	.s-header-soc li {
		margin-right: 15px; }
	.s-home-toggle {
		margin-top: 7px; } }
